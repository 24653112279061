














































import {Component, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {IInvoice} from "@/interfaces/models/IInvoice";
import * as actionTypes from '@/store/action-types';
import moment from "moment";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class InvoicesTable extends Vue {
  @Getter invoices!: IInvoice[];
  formatter: Intl.NumberFormat = new Intl.NumberFormat("en", {
    style: 'currency',
    currency: 'USD'
  });

  async mounted(): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.FETCH_INVOICES);
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  formatAmount(amount: number): string {
    return this.formatter.format(amount);
  }

  formatDate(date: string): string {
    return moment(date).format('LL')
  }
}
