






















































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import EmojiPicker from "@/components/emoji-picker/EmojiPicker.vue";

@Component({
  components: { EmojiPicker }
})
export default class EditReplyPopup extends Vue {
  @Prop({ type: Boolean, required: true }) showDialog!: boolean;
  @Prop({ type: Object, default: null }) reply!: ISavedReply | null;

  @Validate({ required })
  replyName: string = "";

  @Validate({ required })
  replyBody: string = "";

  isLoading: boolean = false;

  @Watch("reply")
  observeReply(): void {
    this.replyName = this.reply?.name || "";
    this.replyBody = this.reply?.content || "";
  }

  get dialogTitle(): string {
    return this.reply?.id ? "Edit reply" : "Create new reply";
  }

  get saveBtnText(): string {
    return this.reply?.id ? "Save" : "Create";
  }

  @Emit("close-popup")
  closePopup(): void {}

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch("showDialog")
  showDialogWatcher(): void {
    if (this.showDialog) {
      document.addEventListener("keyup", this.handleCloseDialog);
    } else {
      document.removeEventListener("keyup", this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.closePopup();
    }
  }

  async saveReply(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const actionType = this.reply?.id
        ? actionTypes.UPDATE_SAVED_REPLY
        : actionTypes.CREATE_SAVED_REPLY;
      await this.$store.dispatch(actionType, {
        ...this.reply,
        name: this.replyName,
        content: this.replyBody.trim()
      });
      this.closePopup();
      this.$v.$reset();
      this.replyName = this.replyBody = "";
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleEmojiSelect(emoji: string): void {
    const lastCursorPosition = document.querySelector("textarea")?.selectionStart || 0;
    this.replyBody =
      this.replyBody.substr(0, lastCursorPosition) +
      emoji +
      this.replyBody.substr(lastCursorPosition);

    // outside click to close emoji picker
    document.body.click();
  }
}
