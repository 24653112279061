

















































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { IDepartment } from '@/interfaces/models/IDepartment';
import { ICreateDepartment } from '@/interfaces/requests/ICreateDepartment';
import { Getter } from 'vuex-class';
import IAgentAccount from '@/interfaces/models/agent-account.interface';

@Component
export default class DepartmentEditPopup extends Vue {
  @Getter agents!: IAgentAccount[];

  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  @Prop({type: Object, default: null}) department!: IDepartment | null;

  @Validate({required})
  name: string = '';
  isLoading: boolean = false;
  selectedAgents: IAgentAccount[] = [];
  search: string = '';

  showAgents: boolean = false;

  @Watch('department', {immediate: true})
  observeDepartment(): void {
    this.name = this.department?.name || '';
    this.selectedAgents = [...this.department?.agentAccounts || []]
  }

  @Watch('showPopup')
  showPopupWatcher(): void {
    if (this.showPopup) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  get availableAgents(): IAgentAccount[] {
    if (!this.search) {
      return this.agents;
    } else {
      return this.agents.filter(agent => this.agentsFilter(agent, this.search));
    }
  }

  get isNameEditable(): boolean {
    return this.department?.id ? !this.department.isNameEditable : false;
  }

  /**
   * Closing dialog
   */
  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }

  /**
   * Close agents list popup
   */
  closeAgents(): void {
    if (this.showAgents) {
      this.showAgents = false;
    }
  }

  /**
   * Handle creating of updating department
   */
  async save(): Promise<void> {
    this.$v.$touch();

    try {
      this.isLoading = true;

      if (this.department?.id) {
        if (this.$v.$invalid) {
          return;
        }

        await this.$store.dispatch(actionTypes.UPDATE_DEPARTMENT, {
          id        : this.department.id,
          updateData: {
            name           : this.name,
            agentAccountIds: this.selectedAgents.map(({id}: IAgentAccount) => id),
          }
        });
      } else {
        await this.$store.dispatch(actionTypes.CREATE_DEPARTMENT, {
          name           : this.name,
          agentAccountIds: this.selectedAgents.map(({id}: IAgentAccount) => id),
        } as ICreateDepartment);
      }
      this.$v.$reset();
      this.closePopup();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      })
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Removing or adding agent to list of selected
   */
  toggleAgent(agent: IAgentAccount): void {
    if (this.isAgentSelected(agent.id)) {
      this.selectedAgents = this.selectedAgents.filter(({id}: IAgentAccount) => id !== agent.id);
    } else {
      this.selectedAgents = this.selectedAgents.concat(agent);
    }
  }

  /**
   * Check if agent already selected
   */
  isAgentSelected(agentId: number): boolean {
    return !!this.selectedAgents.find(({id}: IAgentAccount) => id === agentId);
  }

  /**
   * Filtering contacts by input value
   * @param agent
   * @param value
   */
  agentsFilter(agent: IAgentAccount, value: string): boolean {
    return agent.firstName.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      || agent.lastName.toLocaleLowerCase().includes(value.toLocaleLowerCase());
  }

  get title(): string {
    return this.department?.id ? 'Edit Department' : 'Create Department';
  }
}
