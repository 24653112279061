











import {Vue, Component} from "vue-property-decorator";
import IntegrationList from "@/components/admin-settings/integration-settings/IntegrationList.vue";
@Component({
  components: {IntegrationList}
})
export default class IntegrationsSettings extends Vue {

}
