




































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import * as actionTypes from '@/store/action-types';
import TextodealOffersTable from "@/components/admin-settings/textodeals-settings/TextodealOffersTable.vue";
import EditTextodealOfferPopup from "@/components/admin-settings/textodeals-settings/EditTextodealOfferPopup.vue";
import { NotificationType } from "@/interfaces/models/INotification";
import ITextodealOffer from '@/interfaces/models/textodeal-offer.interface';

@Component({
  components: {ConfirmModal, TextodealOffersTable, EditTextodealOfferPopup}
})
export default class TextodealsSettings extends Vue {
  @Getter textodealOffers!: ITextodealOffer[];

  showDialog: boolean = false;
  showRemovePopup: boolean = false;
  textodealOfferToEdit: ITextodealOffer | null | undefined = null;
  textodealOfferToRemove!: number;
  isRemoving: boolean = false;

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_TEXTODEAL_OFFERS);
  }

  createTextodealOffer(): void {
    this.textodealOfferToEdit = null;
    this.showDialog = true;
  }

  async processRemoving(): Promise<void> {
    try {
      this.isRemoving = true;
      await this.$store.dispatch(actionTypes.REMOVE_TEXTODEAL_OFFER, this.textodealOfferToRemove);
      this.textodealOfferToRemove = 0;
      this.showRemovePopup = false;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isRemoving = false;
    }
  }

  removeTextodealOffer(textodealOfferId: number): void {
    this.textodealOfferToRemove = textodealOfferId;
    this.showRemovePopup = true
  }

  editTextodealOffer(textodealOffer: ITextodealOffer): void {
    this.textodealOfferToEdit = textodealOffer;
    this.showDialog = true;
  }
}
