









































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ITag from '@/interfaces/models/tag.interface';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';

@Component({
  name: 'EditTagDialog'
})
export default class EditTagDialog extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, default: null}) tag!: ITag | null;

  @Validate({required}) name: string = '';
  isLoading: boolean = false;

  @Watch('tag')
  observeTag(): void {
    if (this.tag) {
      this.name = this.tag.name;
    }
  }

  get dialogTitle(): string {
    return this.tag?.id ? 'Edit tag' : 'New tag';
  }

  get saveBtnText(): string {
    return this.tag?.id ? 'Save' : 'Create';
  }

  getDialogPayload(): {name: string, id?: number} {
    const payload: any = {
      name: this.name,
    };

    if (this.tag?.id) {
      payload.id = this.tag.id;
    }

    return payload;
  }

  /**
   * Closing dialog
   */
  @Emit('close-dialog')
  closeDialog(): void {
    this.$v.$reset();
    this.name = '';
  }

  async saveTag(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const actionType = this.tag?.id
        ? actionTypes.UPDATE_TAG
        : actionTypes.CREATE_TAG;

      const payload = this.getDialogPayload();

      await this.$store.dispatch(actionType, payload);

      this.closeDialog();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
