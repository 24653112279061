











































import {Component, Vue} from 'vue-property-decorator';
import OverviewSettings from "@/components/admin-settings/billing/OverviewSettings.vue";
import InvoicesTable from "@/components/admin-settings/billing/InvoicesTable.vue";
import BillingContactForm from "@/components/admin-settings/billing/BillingContactForm.vue";
import AdditionalContactEmail from "@/components/admin-settings/billing/AdditionalContactEmail.vue";
import PaymentMethodForm from "@/components/admin-settings/billing/PaymentMethodForm.vue";
import PaymentDetails from "@/components/admin-settings/billing/PaymentDetails.vue";
import ChangePlanForm from "@/components/admin-settings/billing/ChangePlanForm.vue";

@Component({
  components: {
    ChangePlanForm,
    PaymentDetails,
    PaymentMethodForm, AdditionalContactEmail, BillingContactForm, InvoicesTable, OverviewSettings}
})
export default class BillingSettings extends Vue {
  currentTabIndex: number = 0;

  created(): void {
    if (this.$route.query.tab === 'change-plan') {
      this.currentTabIndex = 1;
    }
  }
}
