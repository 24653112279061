





















































import {Vue, Component, Prop, Emit} from 'vue-property-decorator';
import WidgetItemInterface from "@/interfaces/widget-item.interface";

@Component
export default class WidgetTable extends Vue {
  @Prop({type: Array, required: true}) widgetItems!: WidgetItemInterface[];

  @Emit('remove')
  removeWidget(widgetId: string): string {
    return widgetId;
  }

  @Emit('edit')
  editWidget(widgetId: string): string {
    return widgetId;
  }
}
