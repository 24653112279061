











import {Component, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {IPaymentMethod} from "@/interfaces/models/IPaymentMethod";
import * as actionTypes from '@/store/action-types';
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class PaymentDetails extends Vue {
  @Getter paymentMethods!: IPaymentMethod[];

  async mounted(): Promise<void> {
    await this.fetchPaymentMethod();
  }

  async fetchPaymentMethod(): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.FETCH_PAYMENT_METHODS);
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }
}
