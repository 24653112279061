























































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import { ROLES } from "@/common/utils/roles";
import { IRole } from "@/interfaces/models/role.interface";
import API from "@/http/http-client";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Getter } from "vuex-class";
import { IDepartment } from "@/interfaces/models/IDepartment";

@Component({
  name: "EditAgentPopup"
})
export default class EditAgentPopup extends Vue {
  @Getter departments!: IDepartment[];
  @Prop({ type: Boolean, required: true }) showPopup!: boolean;
  @Prop({ type: Object, default: null }) agent!: IAgentAccount | null;

  @Validate({ required })
  firstName: string = "";
  @Validate({ required })
  lastName: string = "";
  @Validate({ required, email })
  email: string = "";
  role: string = ROLES.ADMINISTRATOR;

  roles: IRole[] = [];
  isLoading: boolean = false;
  showDepartmentsList: boolean = false;
  selectedDepartments: IDepartment[] = [];
  search: string = "";

  @Watch("showPopup", { immediate: true })
  observeMember(): void {
    this.firstName = this.agent?.firstName || "";
    this.lastName = this.agent?.lastName || "";
    this.email = this.agent?.email || "";
    this.selectedDepartments = this.agent?.departments || [this.departments[0]];
    this.role = this.agent?.role || ROLES.ADMINISTRATOR;
  }

  @Watch("showPopup")
  showPopupWatcher(): void {
    if (this.showPopup) {
      document.addEventListener("keyup", this.handleCloseDialog);
    } else {
      document.removeEventListener("keyup", this.handleCloseDialog);
      this.$v.$reset();
    }
  }

  async mounted(): Promise<void> {
    const { data } = await API.get("/dog/roles");
    this.roles = data;
  }

  get availableDepartments(): IDepartment[] {
    if (!this.search) {
      return this.departments;
    } else {
      return this.departments.filter(({ name }: IDepartment) =>
        name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  }

  @Emit("close-popup")
  closePopup(): void {}

  closeDepartmentsList(): void {
    if (this.showDepartmentsList) {
      this.showDepartmentsList = false;
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.closePopup();
    }
  }

  /**
   * Removing or adding department to list of selected
   * @param department
   */
  toggleDepartment(department: IDepartment): void {
    if (this.isDepartmentSelected(department.id)) {
      this.selectedDepartments = this.selectedDepartments.filter(
        ({ id }: IDepartment) => id !== department.id
      );
    } else {
      this.selectedDepartments = this.selectedDepartments.concat(department);
    }
  }

  /**
   * Check if department already selected
   * @param departmentId
   */
  isDepartmentSelected(departmentId: number): boolean {
    return !!this.selectedDepartments.find(({ id }: IDepartment) => id === departmentId);
  }

  async save(): Promise<void> {
    this.$v.$touch();

    try {
      this.isLoading = true;

      if (this.agent?.id) {
        if (this.$v.$invalid) {
          return;
        }

        await this.$store.dispatch(actionTypes.UPDATE_AGENT, {
          id: this.agent.id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          departmentIds: this.selectedDepartments.map(({ id }: IDepartment) => id),
          role: this.roles.find((role: IRole) => role.value === this.role)!.key
        });
      } else {
        if (!this.email.length) {
          return;
        }

        await this.$store.dispatch(actionTypes.CREATE_AGENT, {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          departmentIds: this.selectedDepartments.map(({ id }: IDepartment) => id),
          role: this.roles.find((role: IRole) => role.value === this.role)!.key
        });
      }
      this.$v.$reset();
      this.closePopup();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  get title(): string {
    return this.isEditMode ? "Edit team member" : "Invite new team members";
  }

  get emailErrorMessage(): string | null {
    if (!this.$v.email.$invalid || !this.$v.email.$dirty) {
      return null;
    }

    if (this.$v.email.required) {
      return "Field is required";
    } else {
      return "Bad email format";
    }
  }

  get isEditMode(): boolean {
    return !!this.agent?.id;
  }
}
