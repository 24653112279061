



















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ICustomField } from '@/interfaces/models/ICustomField';

@Component({
  name: 'CustomFieldsTable'
})
export default class CustomFieldsTable extends Vue {
  @Prop({type: Array, required: true}) customFields!: ICustomField;
  customFieldDefaultType: string = 'String'

  /**
   * Emitting event for removing custom field
   * @param id - id of custom field
   */
  @Emit('remove-custom-field')
  removeCustomField({id}: ICustomField): number {
    return id;
  }

  /**
   * Emitting event for editing custom field
   * @param customField
   */
  @Emit('edit-custom-field')
  editCustomField(customField: ICustomField): ICustomField {
    return customField;
  }
}
