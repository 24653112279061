






























































































import {Component, Vue} from "vue-property-decorator";
import {Validate} from "vuelidate-property-decorators";
import {maxValue, minValue, required, minLength} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

const validateCardNumber = (cardNumberFormatted: string) => {
  const cardNumber = cardNumberFormatted.trim().replace(/ /g, '')
  if (cardNumber.length < 16) {
    return false
  }

  let s = 0
  let doubleDigit = false
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = +cardNumber[i]
    if (doubleDigit) {
      digit *= 2
      if (digit > 9) {
        digit -= 9
      }
    }
    s += digit
    doubleDigit = !doubleDigit
  }
  return s % 10 === 0
}

@Component
export default class PaymentMethodForm extends Vue {
  @Validate({required, validateCardNumber})
  creditCardNumber: string = '';
  @Validate({
    required, validYear(value: string) {
      return +value >= +new Date().getFullYear().toString()
    }
  })
  expYear: string = '';
  @Validate({required, minValue: minValue(1), maxValue: maxValue(12)})
  expMonth: string = '';
  @Validate({required, minLength: minLength(3)})
  cvv: string = '';

  isLoading: boolean = false;

  async savePaymentMethod(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.UPDATE_PAYMENT_METHOD, {
        cardNumber: Number(this.creditCardNumber.replace(/ /g, '')),
        cvc: Number(this.cvv),
        expireMonth: Number(this.expMonth),
        expireYear: Number(this.expYear),
      });
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
