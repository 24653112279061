


















































































































import {Vue, Component, Prop, Emit, Watch} from "vue-property-decorator";
import WidgetItemInterface from "@/interfaces/widget-item.interface";
import {Validate} from "vuelidate-property-decorators";
import {required, url} from "vuelidate/lib/validators";

@Component
export default class EditWidgetPopup extends Vue {
  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  @Prop({type: Object, default: null}) widget!: WidgetItemInterface | null;

  name: string = '';
  parameterType: string = '';
  @Validate({required})
  widgetName: string = '';
  @Validate({required, url})
  urlLink: string = '';
  message: string = '';
  smsMessage: string = '';

  items: string[] = [
    'URL Starts with',
    'URL Ends with',
  ]

  @Watch('widget')
  observeWidget(): void {
    this.name = this.widget?.name || '';
    this.parameterType = this.widget?.parameterType || '';
    this.urlLink = this.widget?.url || '';
    this.message = this.widget?.message || '';
    this.smsMessage = this.widget?.smsMessage || '';
  }

  get urlErrorMessage(): string | null {
    if (!this.$v.urlLink.$invalid || !this.$v.urlLink.$dirty) {
      return null;
    }

    if (!this.$v.urlLink.required) {
      return 'This field is required';
    }

    return 'Bad link format';
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  saveWidget(): void {
    this.$v.$touch();
  }
}
