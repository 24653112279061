







































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IAutomation } from '@/interfaces/models/IAutomation';
import moment from 'moment';
import { Helpers } from '@/common/utils/helpers.js';

/**
 * Automation item component
 */
@Component({
  name: 'AutomationItem',
})
export default class AutomationItem extends Vue {
  @Prop({required: true, type: Object}) automation!: IAutomation;

  /**
   * Emitting toggling status
   */
  @Emit('toggle-status')
  toggleAutomationStatus(): IAutomation {
    return this.automation;
  }

  /**
   * Emitting opening automation
   */
  @Emit('view')
  viewAutomation(): number {
    return this.automation.id;
  }

  /**
   * Emitting editing automation
   */
  @Emit('edit')
  editAutomation(): IAutomation {
    return this.automation;
  }

  /**
   * Emitting removing automation
   */
  @Emit('remove')
  removeAutomation(): IAutomation {
    return this.automation;
  }

  /**
   * Formatting automation creating date to next format August 14, 2021
   */
  get formattedDate(): string {
    return moment(this.automation.createdAt).format('LL');
  }

  /**
   * Truncated automation text to prevent overflowing
   */
  get truncatedAutomationName(): string {
    return Helpers.truncateText(this.automation?.name, 25);
  }
}
