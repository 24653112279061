




































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import EditReplyPopup from "@/components/admin-settings/reply-settings/EditReplyPopup.vue";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import * as actionTypes from '@/store/action-types';
import SavedRepliesPreviewTable
  from "@/components/admin-settings/reply-settings/SavedRepliesPreviewTable.vue";
import { NotificationType } from "@/interfaces/models/INotification";

@Component({
  components: {ConfirmModal, SavedRepliesPreviewTable, EditReplyPopup}
})
export default class SavedRepliesSettings extends Vue {
  @Getter savedReplies!: ISavedReply[];

  showDialog: boolean = false;
  showRemovePopup: boolean = false;
  replyToRemove!: number;
  replyToEdit: ISavedReply | null | undefined = null;
  isRemoving: boolean = false;

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_SAVED_REPLIES);
  }

  createReply(): void {
    this.replyToEdit = null;
    this.showDialog = true;
  }

  async processRemoving(): Promise<void> {
    try {
      this.isRemoving = true;
      await this.$store.dispatch(actionTypes.REMOVE_SAVED_REPLY, this.replyToRemove);
      this.replyToRemove = 0;
      this.showRemovePopup = false;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isRemoving = false;
    }
  }

  removeReply(replyId: number): void {
    this.replyToRemove = replyId;
    this.showRemovePopup = true
  }

  editReply(reply: ISavedReply): void {
    this.replyToEdit = reply;
    this.showDialog = true;
  }
}
