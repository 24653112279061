
















































import {Component, Emit, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import * as actionTypes from '@/store/action-types';
import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import {IStripePrice} from "@/interfaces/models/IStripePrice";
import {IMessageCountHistory} from "@/interfaces/models/IMessageCountHistory";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class OverviewSettings extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter agents!: IAgentAccount[];
  @Getter messageCountHistory!: IMessageCountHistory[];
  @Getter stripePrices!: IStripePrice[];
  @Getter stripeProducts!: IStripeProduct[];

  @Emit('change-plan')
  changePlan(): void {
  }

  @Watch('currentBusinessAccount', {immediate: true})
  async currentBusinessAccountWatcher(): Promise<void> {
    if (!this.currentBusinessAccount) {
      return;
    }

    await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRODUCTS);
    await Promise.all([
      this.$store.dispatch(actionTypes.FETCH_STRIPE_PRICES, this.currentProduct?.id),
      this.$store.dispatch(actionTypes.FETCH_AGENTS)
    ]);
  }

  get planDetails(): string {
    return Helpers.getPlanDetails(this.currentBusinessAccount, this.stripeProducts, this.stripePrices);
  }

  get formattedMessageCountHistory(): string {
    if (!this.currentBusinessAccount) {
      return '-'
    }

    let countInfo = `${this.currentBusinessAccount.currentPeriodMessageCount} of ${this.currentBusinessAccount.currentPeriodMessageLimit} used.`;

    if (this.currentBusinessAccount.currentPeriodMessageCount > this.currentBusinessAccount.currentPeriodMessageLimit) {
      const exceed = Math.abs(this.currentBusinessAccount.currentPeriodMessageCount - this.currentBusinessAccount.currentPeriodMessageLimit);
      countInfo += ` You exceeded your plan by ${exceed} messages.`;
    }

    return countInfo;
  }

  get currentProduct(): IStripeProduct | undefined {
    if (!this.currentBusinessAccount) {
      return undefined;
    }

    return this.stripeProducts.find(p => !p.name.toLowerCase().includes('trial'));
  }

  get currentPrice(): IStripePrice | undefined {
    if (!this.currentBusinessAccount) {
      return undefined;
    }

    return this.stripePrices.find(p => p.id === this.currentBusinessAccount?.currentPriceId);
  }
}
