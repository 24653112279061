










































import {Component, Emit, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class CallForwardingTable extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;

  @Emit('edit')
  edit(): void {
  }

  @Emit('remove')
  remove(forwardingId: string): string {
    return forwardingId;
  }

  formatNumber(number: string): string {
    return number ? Helpers.formatNumber(number) : '';
  }
}
