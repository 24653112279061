
























































import { Component, Vue } from "vue-property-decorator";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Getter } from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { IStripePrice, StripeCurrency } from "@/interfaces/models/IStripePrice";
import { IStripeProduct } from "@/interfaces/models/IStripeProduct";
import { ProductService } from "@/http/services/product.service";

@Component
export default class ChangePlanForm extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter stripePrices!: IStripePrice[];
  @Getter stripeProducts!: IStripeProduct[];

  slider: number = 0;
  isUSD: boolean = true;
  countOfUsers: number = 3;
  isLoading: boolean = false;

  get pricePerUser(): number {
    return this.stripePrices.find(({currency, nbAgentAccount}) => currency === this.currentCurrency && nbAgentAccount === 1)?.amount || 0;
  }

  get currentCurrency(): StripeCurrency {
    return this.isUSD ? StripeCurrency.USD : StripeCurrency.CAD;
  }

  get totalPrice(): number {
    return this.stripePrices.find(({nbAgentAccount, currency}) => currency === this.currentCurrency && nbAgentAccount === this.countOfUsers)?.amount || 0;
  }

  get currentProduct(): IStripeProduct | undefined {
    return this.stripeProducts.find(p => !p.name.toLowerCase().includes('trial'));
  }

  get maxUserCount(): number {
    if (!this.stripePrices.length) {
      return 1;
    }

    this.countOfUsers = this.stripePrices.find(p => p.id === this.currentBusinessAccount.currentPriceId)?.nbAgentAccount || 3;

    return Math.max(...this.stripePrices
      .filter((p) => p.currency === (this.isUSD ? StripeCurrency.USD : StripeCurrency.CAD))
      .map(p => p.nbAgentAccount));
  }

  async created(): Promise<void> {
    await this.setCurrentCurrency();
  }

  async setCurrentCurrency(): Promise<void> {
    if (this.currentBusinessAccount.isUnlimited) {
      return;
    }

    const prices = await ProductService.fetchPrices(this.currentBusinessAccount.currentProductId);
    const currentPrice = prices.find(p => p.id === this.currentBusinessAccount.currentPriceId);
    this.isUSD = currentPrice!.currency === StripeCurrency.USD;
  }

  increaseCount(): void {
    this.countOfUsers += 1;
  }

  decreaseCount(): void {
    if (this.countOfUsers > 3) {
      this.countOfUsers -= 1;
    }
  }

  async updateSubscription(): Promise<void> {
    try {
      this.isLoading = true;

      const newPrice = this.stripePrices
        .find(p => p.nbAgentAccount === this.countOfUsers && p.currency === (this.isUSD ? StripeCurrency.USD : StripeCurrency.CAD));
      await this.$store.dispatch(actionTypes.UPDATE_SUBSCRIPTION, newPrice!.id);

      // It takes a while to change plan so I add it to prevent error
      setTimeout(async () => {
        await this.$store.dispatch(actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID);
        this.$emit('plan-changed');
        await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: 'Success! It\'s all good.',
          type: NotificationType.SUCCESS
        });
        this.isLoading = false;
      }, 5_000);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
      this.isLoading = false;
    }
  }
}
