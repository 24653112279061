























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ITextodealOffer from '@/interfaces/models/textodeal-offer.interface';
import moment from 'moment';
import API from "@/http/http-client";
import { ITextodealOfferStatus } from "@/interfaces/models/textodeal-offer-status.interface";

@Component
export default class TextodealOffersTable extends Vue {
  @Prop({type: Array, required: true}) textodealOffers!: ITextodealOffer[];

  OfferStatuses: ITextodealOfferStatus[] = [];


  async mounted(): Promise<void> {
    this.OfferStatuses = await API.get('/deal/offer-statuses').then(res => {return res.data});
  }

  @Emit('edit')
  edit(textodealOffer: ITextodealOffer): ITextodealOffer {
    return textodealOffer;
  }

  @Emit('remove')
  remove(textodealOfferId: number): number {
    return textodealOfferId;
  }

  formattedDate(date: string): string {
    return moment(date).format('LL');
  }

  getOfferStatus(statusKey: string): string {
     return this.OfferStatuses.find((OfferStatus => OfferStatus.name === statusKey))?.value || '';
  }
}
