















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class SavedRepliesPreviewTable extends Vue {
  @Prop({type: Array, required: true}) savedReplies!: ISavedReply[];

  @Emit('edit')
  edit(reply: ISavedReply): ISavedReply {
    return reply;
  }

  @Emit('remove')
  remove(replyId: number): number {
    return replyId;
  }

  truncatedContent(content: string): string {
    return Helpers.truncateText(content, 24);
  }
}
