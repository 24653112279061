

























































































































































































import {Component, Vue} from "vue-property-decorator";
import {Validate} from "vuelidate-property-decorators";
import {email, required} from "vuelidate/lib/validators";
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {ICountry, IProvince} from "@/interfaces/models/location.interface";
import {LocationService} from "@/http/services/location.service";
import store from "@/store";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class BillingContactForm extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;

  @Validate({required})
  companyName: string = '';
  @Validate({required, email})
  email: string = '';
  @Validate({required})
  phoneNumber: string = '';
  @Validate({required})
  address: string = '';
  addressLine2: string = '';
  @Validate({required})
  city: string = '';
  @Validate({required})
  zipCode: string = '';
  @Validate({required})
  countryId: number = 0;
  @Validate({required})
  provinceId: number = 0;

  countries: ICountry[] = [];
  provinces: IProvince[] = [];
  isLoading: boolean = false;

  async mounted(): Promise<void> {
    this.copyBusinessAccountInfo();
    await Promise.all([
      this.fetchCountries(),
      this.fetchProvinces(this.countryId)
    ]);
  }

  get emailErrorMessage(): string | null {
    if (!this.$v.email.$invalid || !this.$v.$dirty) {
      return null;
    }

    if (!this.$v.email.required) {
      return 'Field is required';
    } else {
      return 'Bad email format';
    }
  }

  copyBusinessAccountInfo(): void {
    this.email = this.currentBusinessAccount.email;
    this.phoneNumber = Helpers.formatNumber(this.currentBusinessAccount.messageProxyNumber);
    this.address = this.currentBusinessAccount.address.address;
    this.addressLine2 = this.currentBusinessAccount.address?.additionalAddress || '';
    this.countryId = this.currentBusinessAccount.address.country.id;
    this.provinceId = this.currentBusinessAccount.address.province.id;
    this.zipCode = this.currentBusinessAccount.address.postCode;
    this.companyName = this.currentBusinessAccount.name;
    this.city = this.currentBusinessAccount.address.city;
  }

  async fetchCountries(): Promise<void> {
    this.countries = await LocationService.fetchCountries();
  }

  async fetchProvinces(countryId: number): Promise<void> {
    this.provinces = await LocationService.fetchProvinces(countryId);
  }

  async saveChanges(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT_ADDRESS, {
        postCode: this.zipCode,
        city: this.city,
        address: this.address,
        additionalAddress: this.addressLine2.trim().length ? this.addressLine2 : null,
        province: this.provinceId,
        country: this.countryId,
      });
      await store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: 'Address updated successfully!',
        type: NotificationType.SUCCESS,
      });
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
