














































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {Helpers} from "@/common/utils/helpers.js";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class EditForwardingPopup extends Vue {
  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  @Getter currentBusinessAccount!: IBusinessAccount;

  isLoading: boolean = false;

  callForwardingNumber: string = '';

  @Watch('currentBusinessAccount', {immediate: true})
  observeForwarding(): void {
    this.callForwardingNumber = this.currentBusinessAccount.callForwardingNumber || '';
  }

  @Watch('showPopup')
  showPopupWatcher(): void {
    if (this.showPopup) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }

  get textodogNumber(): string {
    return this.formatNumber(this.currentBusinessAccount.messageProxyNumber);
  }

  formatNumber(number: string): string {
    return number ? Helpers.formatNumber(number) : ''
  }

  async saveCallForward(): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {
        callForwardingNumber: this.callForwardingNumber.replace(/[() -]/g, '')
      });
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }

    this.closePopup();
  }
}
