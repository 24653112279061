
































































































































































































































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {Validate, Validations} from "vuelidate-property-decorators";
import {required} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";
import ITextodealOffer from '@/interfaces/models/textodeal-offer.interface';
import { ITextodealOfferType } from "@/interfaces/models/textodeal-offer-type.interface";
import { ITextodealDiscountType } from "@/interfaces/models/textodeal-discount-type.interface";
import API from "@/http/http-client";
import ImageDropArea from "@/common/reusable-components/ImageDropArea.vue";
import { IAutomation } from '@/interfaces/models/IAutomation';
import { Getter } from 'vuex-class';
import ITextodealProvider from '@/interfaces/models/textodeal-provider.interface';
import IBusinessAccount from '@/interfaces/models/business-account.interface';

@Component({
  components: {ImageDropArea}
})
export default class EditTextodealOfferPopup extends Vue {
  @Getter automations!: IAutomation[];
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter currentTextodealProvider!: ITextodealProvider;
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, default: null}) textodealOffer!: ITextodealOffer | null;

  discount: number | null = null;
  discountType: string| null = null;
  addedValueEn: string | null = null;
  addedValueFr: string | null = null;

  @Validations()
    validations() {
        if (this.showDiscountFields()) {
            return {
                discount: {required},
                discountType: {required}
            }
        }
        if (this.showAddedValueFields()) {
            return {
                addedValueEn: {required},
                addedValueFr: {required}
            }
        }
        return {}
    }

  @Validate({required})
  automationId: number = 0;
  @Validate({required})
  titleEn: string = '';
  @Validate({required})
  titleFr: string = '';
  @Validate({required})
  initialAmount: number = 0;
  @Validate({required})
  offerType: string = '';

  descriptionEn: string | null = null;
  descriptionFr: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  thumb: string | null = null;

  isLoading: boolean = false;
  thumbWasChanged: boolean = false;
  offerTypes: ITextodealOfferType[] = [];
  discountTypes: ITextodealDiscountType[] = [];
  currentThumb: string = ''
  startDatePicker: boolean = false;
  endDatePicker: boolean = false;

  @Watch('textodealOffer')
  observeTextodealOffer(): void {
    this.automationId = this.textodealOffer?.automationId || 0;
    this.titleEn = this.textodealOffer?.titleTranslations.en || '';
    this.titleFr = this.textodealOffer?.titleTranslations.fr || '';
    this.descriptionEn = this.textodealOffer?.descriptionTranslations.en || null;
    this.descriptionFr = this.textodealOffer?.descriptionTranslations.fr || null;
    this.initialAmount = this.textodealOffer?.initialAmount || 0;
    this.offerType = this.textodealOffer?.offerType || 'discount';
    this.discountType = this.textodealOffer?.discountType || this.discountTypes[0].name;
    this.discount = this.textodealOffer?.discount || null;
    this.addedValueEn = this.textodealOffer?.addedValueTranslations.en || null;
    this.addedValueFr = this.textodealOffer?.addedValueTranslations.fr || null;
    this.startDate = this.textodealOffer?.startDate ? this.textodealOffer?.startDate.split(' ')[0] : null;
    this.endDate = this.textodealOffer?.endDate ? this.textodealOffer?.endDate.split(' ')[0] : null;
  }

  async mounted(): Promise<void> {
    this.offerTypes = await API.get('/deal/offer-types').then(res => {return res.data});
    this.discountTypes = await API.get('/deal/discount-types').then(res => {return res.data});
    await this.$store.dispatch(actionTypes.FETCH_AUTOMATIONS, 'keyword');
    await this.$store.dispatch(actionTypes.FETCH_TEXTODEAL_PROVIDER, this.currentBusinessAccount.id);
  }

  showDiscountFields() {
    return this.offerType === 'discount';
  }

  showAddedValueFields() {
    return this.offerType === 'added_value';
  }

  get dialogTitle(): string {
    return this.textodealOffer?.id ? 'Edit offer' : 'Create new offer';
  }

  get saveBtnText(): string {
    return this.textodealOffer?.id ? 'Save' : 'Create';
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  handleFileSelecting(file: string | null): void {
    this.thumb = file;
    this.thumbWasChanged = true;
  }

  @Watch('showDialog')
  showDialogWatcher(): void {
    if (this.showDialog) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }

  async saveTextodealOffer(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const textodealOffer = {
          providerId: this.currentTextodealProvider.id,
          automationId: this.automationId,
          title: {'en': this.titleEn, 'fr': this.titleFr},
          description: {'en': this.descriptionEn, 'fr': this.descriptionFr},
          initialAmount: this.initialAmount,
          offerType: this.offerType,
          discountType: this.discountType,
          discount: this.discount,
          addedValueEn: this.addedValueEn,
          addedValueFr: this.addedValueFr,
          startDate: this.startDate,
          endDate: this.endDate,
          thumb: this.thumb
        };
      if (this.textodealOffer?.id) {
        await this.$store.dispatch(actionTypes.UPDATE_TEXTODEAL_OFFER, {...textodealOffer, id: this.textodealOffer?.id});
      } else {
        await this.$store.dispatch(actionTypes.CREATE_TEXTODEAL_OFFER, {...textodealOffer})
      }
      this.closePopup();
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: 'The offer has been ' + (this.textodealOffer?.id ? 'updated' : 'created') + ' successfully !',
          type: NotificationType.SUCCESS
        });
      this.$v.$reset();
      // this.title = '';
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
