

































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import IAgentAccount, { AgentStatus } from "@/interfaces/models/agent-account.interface";

@Component
export default class AgentCard extends Vue {
  @Prop({type: Object, required: true}) agent!: IAgentAccount;
  @Prop({type: Boolean, required: true}) canDeactivate!: boolean;

  isShowResendMessage: boolean = true;

  @Emit('edit')
  edit(): number {
    return this.agent.id;
  }

  @Emit('activate')
  activate(): number {
    return this.agent.id;
  }

  @Emit('deactivate')
  deactivate(): number {
    return this.agent.id;
  }

  @Emit('resend-invitation')
  resendInvitation(): number {
    this.isShowResendMessage = false;
    return this.agent.id;
  }

  get formattedStatus(): string {
    switch (this.agent.status.toLowerCase()) {
      case AgentStatus.ACTIVE:
        return 'Active';
      case AgentStatus.FAILURE:
        return 'Inactive';
      default:
        return 'Inactive';
    }
  }

  get isActive(): boolean {
    return this.agent.status.toLowerCase() === AgentStatus.ACTIVE;
  }

  get isPending(): boolean {
    return this.agent.status.toLowerCase() === AgentStatus.PENDING;
  }

  get isCanActivate(): boolean {
    const agent_status =  this.agent.status.toLowerCase();
    return (agent_status !== AgentStatus.ACTIVE && agent_status !== AgentStatus.PENDING);
  }

  get isCanDeactivate(): boolean {
    return this.isActive;
  }

  get isCanResendInvitation(): boolean {
    return this.isPending;
  }
}
