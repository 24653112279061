























import {Component, Vue} from 'vue-property-decorator';
import SettingItem from "@/components/admin-settings/SettingItem.vue";
import SettingItemInterface from "@/interfaces/setting-item.interface";
import {ADMIN_MENU_ITEMS} from "@/common/utils/admin-menu-items";
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";

@Component({
  components: {SettingItem}
})
export default class AdminSettings extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;

  mounted(): void {
    if (this.$route.path === '/settings' && !this.$vuetify.breakpoint.xs) {
      this.$router.push('/settings/company');
    }
  }

  get menuItems(): SettingItemInterface[] {
    return ADMIN_MENU_ITEMS
      .filter(item => !item.unlimitedOnly || !this.currentBusinessAccount.isUnlimited);
  }

  get isPureSettings(): boolean {
    return this.$route.path === '/settings';
  }

  get isWhiteBackground(): boolean {
    return this.$route.meta?.whiteBackground;
  }
}
