



































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {ITimezone} from "@/interfaces/models/timezone.interface";
import * as actionTypes from "@/store/action-types";
import {Getter} from "vuex-class";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {LocationService} from "@/http/services/location.service";
import {NotificationType} from "@/interfaces/models/INotification";
import imageLoadingHelper from "@/common/utils/image-loading-helper";

@Component
export default class GeneralCompanySettingsTab extends  Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;
  timezones: ITimezone[] = [];
  isUploadingImage: boolean = false;
  allowTransactionalMessage: boolean = false;
  notifyWhenExistingContactSubscribe: boolean = false;
  notifyWhenNewContactSubscribe: boolean = false;

  async mounted(): Promise<void> {
    this.allowTransactionalMessage = this.currentBusinessAccount.allowTransactionalMessage;
    this.notifyWhenExistingContactSubscribe = this.currentBusinessAccount.notifyWhenExistingContactSubscribe;
    this.notifyWhenNewContactSubscribe = this.currentBusinessAccount.notifyWhenNewContactSubscribe;
    await this.fetchTimezones();
  }

  async fetchTimezones(): Promise<void> {
    try {
      this.timezones = await LocationService.fetchTimezones();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async changeTimeZone(timezoneId: number): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {timezoneId});
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async changeTransactionalMessage(): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {allowTransactionalMessage: this.allowTransactionalMessage});
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async changeExistingContactSubscribe(): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {notifyWhenExistingContactSubscribe: this.notifyWhenExistingContactSubscribe});
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }
  async changeNewContactSubscribe(): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {notifyWhenNewContactSubscribe: this.notifyWhenNewContactSubscribe});
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  showSwitchLabel() : string {
    if(this.allowTransactionalMessage) {
      return "Allow Transactional Messages";
    }else {
      return "Allow Transactional Messages";
    }
  }

  filterTimezones(timezone: ITimezone, value: string) {
    return `${timezone.zoneName} (GMT${timezone.offset / 3600})`
      .toLowerCase()
      .includes(value.trim().toLowerCase());
  }

  async handleImageSelect(event: any): Promise<void> {
    try {
      this.isUploadingImage = true;

      const base64File = await imageLoadingHelper(event)
      await this.$store.dispatch(actionTypes.UPDATE_BUSINESS_ACCOUNT, {photo: base64File});
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isUploadingImage = false;
    }
  }
}
