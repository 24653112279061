























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICustomField } from '@/interfaces/models/ICustomField';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';

@Component({
  name: 'EditCustomFieldDialog'
})
export default class EditCustomFieldDialog extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, default: null}) customField!: ICustomField | null;

  @Validate({required}) name: string = '';
  customFieldTypes: string[] = ['String'];
  // Right now custom fields support only String type
  @Validate({required}) customFieldType: string = this.customFieldTypes[0];

  isLoading: boolean = false;

  @Watch('customField')
  observeCustomField(): void {
    if (this.customField) {
      this.name = this.customField.name;
    }
  }

  get dialogTitle(): string {
    return this.customField?.id ? 'Edit custom field' : 'New custom field';
  }

  get saveBtnText(): string {
    return this.customField?.id ? 'Save' : 'Create';
  }

  getDialogPayload(): {name: string, id?: number} {
    const payload: any = {
      name: this.name,
    };

    if (this.customField?.id) {
      payload.id = this.customField.id;
    }

    return payload;
  }

  /**
   * Closing dialog
   */
  @Emit('close-dialog')
  closeDialog(): void {
    this.$v.$reset();
    this.name = '';
  }

  async saveCustomField(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const actionType = this.customField?.id
        ? actionTypes.UPDATE_CUSTOM_FIELD
        : actionTypes.CREATE_CUSTOM_FIELD;

      const payload = this.getDialogPayload();

      await this.$store.dispatch(actionType, payload);

      this.closeDialog();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
