







































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import IAgentAccount, { AgentRole, AgentStatus } from '@/interfaces/models/agent-account.interface';
import AgentCard from '@/components/admin-settings/agent-accounts-settings/AgentCard.vue';
import * as actionTypes from '@/store/action-types';
import { NotificationType } from '@/interfaces/models/INotification';
import EditAgentPopup from '@/components/admin-settings/agent-accounts-settings/EditAgentPopup.vue';
import { IDepartment } from '@/interfaces/models/IDepartment';
import DepartmentEditPopup
  from '@/components/admin-settings/departments-settings/DepartmentEditPopup.vue';
import DepartmentsTable
  from '@/components/admin-settings/departments-settings/DepartmentsTable.vue';
import IBusinessAccount from '@/interfaces/models/business-account.interface';

@Component({
  components: {
    ConfirmModal,
    DepartmentsTable,
    DepartmentEditPopup,
    EditAgentPopup,
    AgentCard
  }
})
export default class OrganizationSettings extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter agents!: IAgentAccount[];
  @Getter departments!: IDepartment[];

  // Team members
  agentToEdit: IAgentAccount | null = null;
  showAgentEditPopup: boolean = false;

  // Departments
  departmentToEdit: IDepartment | null = null;
  showDepartmentEditPopup: boolean = false;

  // Common
  removeConfig: { action: string, id: number } | null = null;
  showRemovePopup: boolean = false;
  activeTabIndex: number = 0;
  removeTitle: string = '';

  /**
   * Calculate number of active agents for preventing deactivation all agents
   */
  get countOfActiveAgents(): number {
    return this.agents
      .reduce((acc, agent) => agent.status === AgentStatus.ACTIVE && agent.role === AgentRole.ADMINISTRATOR ? acc + 1 : acc, 0);
  }

  mounted(): void {
    this.$store.dispatch(actionTypes.FETCH_AGENTS);
    this.$store.dispatch(actionTypes.FETCH_DEPARTMENTS);
  }

  /**
   * Create new department/agent based on current tab index
   */
  createNew(): void {
    if (this.activeTabIndex === 0) {
      this.departmentToEdit = null;
      this.showDepartmentEditPopup = true;
    } else {
      this.agentToEdit = null;
      this.showAgentEditPopup = true;
    }
  }

  /**
   * Removing of department/agent based on remove config
   */
  async processRemoving(): Promise<void> {
    this.showRemovePopup = false;
    try {
      await this.$store.dispatch(this.removeConfig!.action, this.removeConfig!.id);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  /**
   * Activating of agent account
   * @param agentId
   */
  async handleAgentActivation(agentId: number): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.ACTIVATE_AGENT, agentId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR,
      });
    }
  }

  /**
   * Deactivation of agent account
   * @param agentId
   */
  async handleAgentDeactivation(agentId: number): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.DEACTIVATE_AGENT, agentId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR,
      });
    }
  }

  /**
   * Resending invitation for agent
   * @param agentId
   */
  async resendInvitation(agentId: number): Promise<void> {
    try {
      await this.$store.dispatch(actionTypes.RESEND_INVITATION, agentId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR,
      });
    }
  }

  /**
   * Open popup for editing agent
   * @param agentId
   */
  editAgent(agentId: number): void {
    const agent = this.agents.find((agent: IAgentAccount) => agent.id === agentId);

    if (agent) {
      this.agentToEdit = agent;
      this.showAgentEditPopup = true;
    }
  }

  /**
   * Open popup for editing department
   * @param departmentId
   */
  editDepartment(departmentId: number): void {
    const department = this.departments.find(({id}: IDepartment) => id === departmentId);

    if (department) {
      this.departmentToEdit = department;
      this.showDepartmentEditPopup = true;
    }
  }

  /**
   * Setting config and opening remove popup
   */
  removeDepartment(departmentId: number): void {
    const department = this.departments.find(({id}: IDepartment) => id == departmentId);
    this.removeConfig = {
      id    : department!.id,
      action: actionTypes.REMOVE_DEPARTMENT
    };
    this.removeTitle = `You are about to remove ${department?.name || ''} department from your organization, All conversations related to this department will be reassign to Unassigned department. Are you sure you want to do this?`;
    this.showRemovePopup = true;
  }

  /**
   * Removing item
   */
  removeItem(): void {
    this.$store.dispatch(this.removeConfig!.action, this.removeConfig!.id);
    this.showRemovePopup = false;
  }
}
