


















































import { Component, Vue } from 'vue-property-decorator';
import CallForwardingTable
  from "@/components/admin-settings/call-forwarding-settings/CallForwardingTable.vue";
import EditForwardingPopup
  from "@/components/admin-settings/call-forwarding-settings/EditForwardingPopup.vue";
import { Getter } from "vuex-class";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import GeneralCompanySettingsTab
  from "@/components/admin-settings/company-settings/GeneralCompanySettingsTab.vue";
import * as actionTypes from "@/store/action-types";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import AgentCard from "@/components/admin-settings/agent-accounts-settings/AgentCard.vue";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { NotificationType } from "@/interfaces/models/INotification";

@Component({
  components: {
    ConfirmModal,
    AgentCard,
    GeneralCompanySettingsTab,
    EditForwardingPopup,
    CallForwardingTable
  }
})
export default class CompanySettings extends Vue {
  @Getter agents!: IAgentAccount[];
  @Getter currentBusinessAccount!: IBusinessAccount;

  showCallForwardingEditPopup: boolean = false;
  showRemovePopup: boolean = false;
  callToRemove: string = '';
  forwardingToEdit: object | null = null;

  currentTabIdx: number = 0;

  agentToEdit: IAgentAccount | null = null;
  showAgentEditPopup: boolean = false;
  removeConfig: { action: string, id: number } | null = null;

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_AGENTS);
  }

  handleAgentRemoving(id: number): void {
    this.removeConfig = {
      action: actionTypes.REMOVE_AGENT,
      id
    };
    this.showRemovePopup = true;
  }

  async processRemoving(): Promise<void> {
    this.showRemovePopup = false;
    try {
      await this.$store.dispatch(this.removeConfig!.action, this.removeConfig!.id);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  removeCallForwarding(forwardingId: string): void {
    this.callToRemove = forwardingId;
    this.showRemovePopup = true
  }

  handleCreating(): void {
    if (this.currentTabIdx === 1) {
      this.forwardingToEdit = null;
      this.showCallForwardingEditPopup = true;
    }
  }

  editForwarding(): void {
    this.showCallForwardingEditPopup = true;
  }

  get createBtnName(): string {
    return this.currentTabIdx === 1 ? 'Create new' : 'Invite new';
  }

  get removeLabel(): string {
    return this.currentTabIdx === 1 ? 'Remove this call forwarding?' : 'Remove this team member?';
  }
}
