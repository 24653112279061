





































import { Component, Vue } from "vue-property-decorator";
import WidgetTable from "@/components/admin-settings/widget-setting/WidgetTable.vue";
import WidgetItemInterface from "@/interfaces/widget-item.interface";
import EditWidgetPopup from "@/components/admin-settings/widget-setting/EditWidgetPopup.vue";
import { Getter } from "vuex-class";
import { widgets } from "@/store/modules/widgets";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";

@Component({
  components: {ConfirmModal, EditWidgetPopup, WidgetTable}
})
export default class WidgetSettings extends Vue {
  @Getter('getWidgets', {namespace: 'widgets'}) widgetItems!: WidgetItemInterface[];
  isShowEditPopup: boolean = false;
  showRemovePopup: boolean = false;
  widgetToRemove: string = '';
  widgetToEdit: WidgetItemInterface | null | undefined = null;

  async mounted(): Promise<void> {
    await this.$store.dispatch('widgets/fetchWidgets');
  }

  createWidget(): void {
    this.widgetToEdit = null;
    this.isShowEditPopup = true;
  }

  editWidget(widgetId: string): void {
    this.widgetToEdit = this.widgetItems.find((widget: WidgetItemInterface) => widget.widgetId === widgetId);
    this.isShowEditPopup = true;
  }

  processRemoving(): void {
    this.showRemovePopup = false;
    this.$store.dispatch('widgets/removeWidget', this.widgetToRemove);
    this.widgetToRemove = '';
  }

  removeWidget(widgetId: string): void {
    this.widgetToRemove = widgetId;
    this.showRemovePopup = true;
  }
}
