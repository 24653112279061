



























import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import {Validate} from "vuelidate-property-decorators";
import {email} from "vuelidate/lib/validators";

@Component
export default class AdditionalContactEmail extends Vue {
  // @Getter('getAdditionalEmail', {namespace: 'billing'}) additionalEmail!: string;
  // @Validate({email})
  // email: string = '';
  // isLoading: boolean = false;
  //
  // @Watch('additionalEmail')
  // observeAdditionalEmail(): void {
  //   this.email = this.additionalEmail;
  // }
  //
  // async saveEmail(): Promise<void> {
  //   this.$v.$touch();
  //
  //   if (this.$v.$invalid || !this.email.length) {
  //     return;
  //   }
  //
  //   this.isLoading = true;
  //   await this.$store.dispatch('billing/updateAdditionEmail', this.email);
  //   this.isLoading = false;
  // }
}
