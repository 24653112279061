





























import {Component, Emit, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import { IDepartment } from '@/interfaces/models/IDepartment';

@Component
export default class DepartmentsTable extends Vue {
  @Getter departments!: IDepartment[];

  @Emit('edit')
  edit(departmentId: number): number {
    return departmentId;
  }

  @Emit('remove')
  remove(departmentId: number): number {
    return departmentId;
  }

  getCountOfAgentsText(count: number): string {
    return count === 1 ? `1 assigned agent` : `${count} assigned agents`;
  }
}
