




























































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ICustomField } from "@/interfaces/models/ICustomField";
import ITag from "@/interfaces/models/tag.interface";
import * as actionTypes from "@/store/action-types";
import {
  ConfirmModalButtonColorEnum,
  IConfirmModal,
} from "@/interfaces/confirm-modal.interface";
import { DEFAULT_CONFIRM_MODAL_CONFIG } from "@/common/constants";
import { NotificationType } from "@/interfaces/models/INotification";
import CustomFieldsTable from "@/components/admin-settings/custom-fields-settings/CustomFieldsTable.vue";
import TagsTable from "@/components/admin-settings/custom-fields-settings/TagsTable.vue";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import EditCustomFieldDialog from "@/components/admin-settings/custom-fields-settings/EditCustomFieldDialog.vue";
import EditTagDialog from "@/components/admin-settings/custom-fields-settings/EditTagDialog.vue";

@Component({
  name: "CustomFieldsSettings",
  components: { EditCustomFieldDialog, EditTagDialog, ConfirmModal, CustomFieldsTable, TagsTable },
})
export default class CustomFieldsSettings extends Vue {
  @Getter customFields!: ICustomField[];
  @Getter tags!: ITag[];

  // Custom Fields
  showCreateCustomFieldDialog: boolean = false;
  customFieldToEdit: ICustomField | null = null;
  showConfirmModal: boolean = false;
  confirmLoading: boolean = false;
  confirmModalConfig: IConfirmModal = DEFAULT_CONFIRM_MODAL_CONFIG;

  // Tags
  showCreateTagDialog: boolean = false;
  tagToEdit: ITag | null = null;

  // Common
  activeTabIndex: number = 0;

  mounted(): void {
    this.fetchCustomFields();
    this.fetchTags();
  }

  /**
   * Dispatching action for fetching custom fields
   */
  async fetchCustomFields(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_CUSTOM_FIELDS);
  }

   /**
   * Dispatching action for fetching custom fields
   */
  async fetchTags(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_TAGS);
  }

  /**
   * Opening dialog for creating custom field
   */
  createNew(): void {
    if(this.activeTabIndex === 0) {
    this.customFieldToEdit = null;
    this.showCreateCustomFieldDialog = true;
    } else {
    this.tagToEdit = null;
    this.showCreateTagDialog = true;
    }
  }

  /**
   * Opening dialog for editing custom field
   * @param customField - custom field to edit
   */
  editCustomField(customField: ICustomField): void {
    this.customFieldToEdit = customField;
    this.showCreateCustomFieldDialog = true;
  }

  /**
   * Opening dialog for editing custom field
   * @param tag - custom field to edit
   */
  editTag(tag: ITag): void {
    this.tagToEdit = tag;
    this.showCreateTagDialog = true;
  }

  /**
   * Closing dialog for editing/creating custom fields
   */
  closeCreateCustomFieldDialog(): void {
    this.customFieldToEdit = null;
    this.showCreateCustomFieldDialog = false;
  }

    /**
   * Closing dialog for editing/creating tags
   */
  closeCreateTagDialog(): void {
    this.tagToEdit = null;
    this.showCreateTagDialog = false;
  }

  /**
   * Removing custom field
   */
  removeCustomField(customFieldId: number): void {
    this.confirmModalConfig = {
      title: "Remove custom field",
      label: `Are you sure?`,
      buttonText: "Remove",
      buttonColor: ConfirmModalButtonColorEnum.DELETE,
      confirmAction: async () => {
        try {
          this.confirmLoading = true;
          await this.$store.dispatch(
            actionTypes.REMOVE_CUSTOM_FIELD,
            customFieldId
          );
        } catch (e) {
          await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
            text: e.message,
            type: NotificationType.ERROR,
          });
        } finally {
          this.showConfirmModal = false;
          this.confirmLoading = false;
        }
      },
    };
    this.showConfirmModal = true;
  }

  /**
   * Removing tag
   */
  removeTag(tagId: number): void {
    this.confirmModalConfig = {
      title: "Remove tag",
      label: `Are you sure?`,
      buttonText: "Remove",
      buttonColor: ConfirmModalButtonColorEnum.DELETE,
      confirmAction: async () => {
        try {
          this.confirmLoading = true;
          await this.$store.dispatch(
            actionTypes.REMOVE_TAG,
            tagId
          );
        } catch (e) {
          await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
            text: e.message,
            type: NotificationType.ERROR,
          });
        } finally {
          this.showConfirmModal = false;
          this.confirmLoading = false;
        }
      },
    };
    this.showConfirmModal = true;
  }
}
