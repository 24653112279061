











































































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as actionTypes from '@/store/action-types';
import { IAutomation } from '@/interfaces/models/IAutomation';
import { Getter } from 'vuex-class';
import AutomationItem from '@/components/admin-settings/automations-settings/AutomationItem.vue';
import CreateAutomationModal
  from '@/components/admin-settings/automations-settings/CreateAutomationModal.vue';
import ConfirmModal from '@/components/admin-settings/ConfirmModal.vue';
import { ConfirmModalButtonColorEnum, IConfirmModal } from '@/interfaces/confirm-modal.interface';
import { NotificationType } from '@/interfaces/models/INotification';
import { DEFAULT_CONFIRM_MODAL_CONFIG } from '@/common/constants';

/**
 * Automation list screen in settings
 */
@Component({
  name      : 'Automations',
  components: {ConfirmModal, CreateAutomationModal, AutomationItem}
})
export default class Automations extends Vue {
  @Getter automations!: IAutomation[];
  sortedAutomations: IAutomation[] = [];
  showAutomationDialog: boolean = false;
  automationToEdit: IAutomation | null = null;

  showConfirmModal: boolean = false;
  automationIdToProcess: number | null = null;
  confirmLoading: boolean = false;
  confirmModalConfig: IConfirmModal = DEFAULT_CONFIRM_MODAL_CONFIG;
  currentSortingField: string = '';

  /**
   * Watching on changes with automations
   * Array.prototype.sort mutate value in store and it produce errors
   */
  @Watch('automations', {immediate: true})
  watchAutomations(): void {
    this.sortedAutomations = [...this.automations];
  }

  mounted(): void {
    this.fetchAutomations();
  }

  /**
   * Handle closing automation dialog
   */
  closeAutomationDialog(): void {
    this.showAutomationDialog = false;
    this.automationToEdit = null;
  }

  /**
   * Fetching all automations
   */
  async fetchAutomations(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_AUTOMATIONS);
  }

  /**
   * Open create automation modal
   */
  createAutomation(): void {
    this.showAutomationDialog = true;
  }

  /**
   * Open edit automation modal
   * @param automation
   */
  editAutomation(automation: IAutomation): void {
    this.automationToEdit = automation;
    this.showAutomationDialog = true;
  }

  /**
   * Enabling or disabling automations-settings
   * @param automation
   */
  async toggleAutomation(automation: IAutomation): Promise<void> {
    if (automation.enabled) {
      this.confirmModalConfig = {
        title        : 'Pause automation',
        label        : `Are you sure you want to pause ${automation.name} automation? This will make it private and none of your customers will be able to interact with it.`,
        buttonText   : 'Pause',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          try {
            this.confirmLoading = true;
            await this.$store.dispatch(actionTypes.DISABLE_AUTOMATION, automation.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    } else {
      this.confirmModalConfig = {
        title        : 'Start automation',
        label        : `Are you sure you want to start ${automation.name} automation? This will make it public and all of your customers will be able to interact with it.`,
        buttonText   : 'Start',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          try {
            this.confirmLoading = true;
            await this.$store.dispatch(actionTypes.ENABLE_AUTOMATION, automation.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    }
  }

  /**
   * Removing automation
   * @param automation
   */
  removeAutomation(automation: IAutomation): void {
    this.confirmModalConfig = {
      title        : 'Remove automation',
      label        : `You are about to remove ${automation.name} from the system. This change can not be reversed. Are you sure you want to do this?`,
      buttonText   : 'Remove',
      buttonColor  : ConfirmModalButtonColorEnum.DELETE,
      confirmAction: async () => {
        try {
          this.confirmLoading = true;
          await this.$store.dispatch(actionTypes.REMOVE_AUTOMATION, automation.id);
        } catch (e) {
          await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
            text: e.message,
            type: NotificationType.ERROR
          });
        } finally {
          this.showConfirmModal = false;
          this.confirmLoading = false;
        }
      },
    };
    this.showConfirmModal = true;
  }

  /**
   * Sorting automations by specific field
   * @param field - sort field name
   */
  sortAutomations(field: 'name' | 'enabled'): void {
    if (this.currentSortingField === field) {
      this.sortedAutomations.sort((a, b) => a[field] > b[field] ? -1 : 1);
      this.currentSortingField = '';
    } else {
      this.sortedAutomations.sort((a, b) => a[field] > b[field] ? 1 : -1);
      this.currentSortingField = field;
    }
  }
}
