

















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ITag from '@/interfaces/models/tag.interface';

@Component({
  name: 'TagsTable'
})
export default class TagsTable extends Vue {
  @Prop({type: Array, required: true}) tags!: ITag;

  /**
   * Emitting event for removing custom field
   * @param id - id of custom field
   */
  @Emit('remove-tag')
  removeTag({id}: ITag): number {
    return id;
  }

  /**
   * Emitting event for editing custom field
   * @param tag
   */
  @Emit('edit-tag')
  editTag(tag: ITag): ITag {
    return tag;
  }
}
